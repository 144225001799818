.emp_btn{
    /* padding: 3rem; */
    margin: 0.5rem;
    margin-bottom: 1rem;
}

.emp_req_btn{
    margin-right: 2rem;
}

.emp_leav_req_btn{
    margin-right: 1rem;
    margin-top: 1rem;
}

.emp_center{
    text-align: center;
}