.attendence-content{
    /* padding: 4rem; */
    margin: 8rem;
    text-align: center;
  }

  .date-content{
    /* padding: 3rem; */
    margin-left: 15rem;
  }

  .custom_form{
    text-align: center;
  }

  .button_content{
    margin-left: 1rem;
  }

